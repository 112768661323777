/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import General from "./General";
import "../../../assets/css/media.css";
import Team from "./Team";


toast.configure({
  autoClose: 8000,
  draggable: false,
});

class About extends React.Component {
  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);

    this.activePage = 1;
    this.search = {
      category: "",
      title: "",
      page: this.activePage
    };

    this.myToast = null;
  }


  render() {
    const {
      error = "",
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (
      <>
        <General />
      </>
    );
  }
}

About.propTypes = {
  actions: PropTypes.object.isRequired,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingContents: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default About;