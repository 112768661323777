import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../assets/images/avatar.png";

function Contact(props) {
  return (
    <section id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            <div className="section-title text-center">
              <h3>Get In Touch</h3>
              <p>You can connect to us through a number of channels</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="contact-info col-12 col-lg-4 res-margin">
            <h5>
              <span className="icon icon-basic-geolocalize-05"></span>
              Office Location
            </h5>
            <p>
              Namugongo, Nsawo estate road
              <br />
              Wakiso Uganda
            </p>

            <h5>
              <span className="icon icon-basic-smartphone"></span>
              Phone Number
            </h5>
            <p>
              <a href="tel:+256778456995">+256 778 456 995 </a><br/>
              <a href="tel:+256702791899">+256 702 791 899 </a>
            </p>

            <h5>
              <span className="icon icon-basic-mail"></span>
              Email Address
            </h5>
            <p>
              <a href="mailto:info@riverwidesafaris.com">
                info@riverwidesafaris.com
              </a>
            </p>
          </div>
          <div className="col-12 col-lg-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d346.84800075761837!2d32.6683063669213!3d0.38597626382298367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1712232790197!5m2!1sen!2suk"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
