/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Settings } from "../../utils/Settings";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import parse from 'html-react-parser';

function Sliders(props) {
  const {contents = []} = props;
  return (
    <section id="slider" className="bg-grey">
        <Carousel autoPlay={true} showThumbs={false} showStatus={false}>
        {contents.map(record => {
          return (
            <div key={record.id}>
              <img src={record.path} width="100%" />
              <p className="legend-custom">{parse(record.details)}</p>
            </div>
          )
        })} 
        </Carousel>
        <div className="slider-footer">
          <b>Riverwide Trekkers safaris</b> | Your adventure companion.
        </div>
    </section>
  );
}

export default Sliders;