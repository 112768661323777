import React from "react";
import { Switch, Route } from "react-router-dom";
import SignIn from "../components/login/index";
import AdminLogin from "../components/login/AdminLogin-container";
import UserRegister from "../components/users/Register-container";
import Logout from "../components/login/Logout";
import Users from "../components/users/index";
import Children from "../components/children/index";
import Contents from "../components/contents/index";
import ServiceAreas from "../components/serviceareas/index";
import ServiceAreaDetails from "../components/serviceareas/Details";
import ContentDetails from "../components/contents/Content-details";
import Home from "../components/home/index";
import About from "../components/pages/about";
import Media from "../components/pages/media";
import GeneralContent from "../components/pages/general";
import Contact from "../components/general/Contact-view";
import { isAdmin } from "../utils/Authentication";
import ContentEdit from "../components/contents/Content-edit-container";
import RegisterEdit from "../components/users/RegisterContainerEdit";
import ContentNewContainer from "../components/contents/Content-new-container";
import EventDetails from "../components/events/Event-details";
import EventNewContainer from "../components/events/Event-new-container";
import EventEdit from "../components/events/Event-edit-container";
import Events from "../components/events/index";
import Uganda from "../components/pages/safaris/ug/uganda";
import Rwanda from "../components/pages/safaris/rw/index";

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" exact component={SignIn} />
      <Route path="/admin" exact component={AdminLogin} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/contents" exact component={Contents} />
      <Route path="/events" exact component={Events} />
      <Route path="/content/:id" exact component={ContentDetails} />
      <Route path="/event/:id" exact component={EventDetails} />
      <Route path="/about" exact component={About} />
      <Route path="/media" exact component={Media} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/uganda" exact component={Uganda} />
      <Route path="/rwanda" exact component={Rwanda} />
      <Route path="/register" exact component={UserRegister} />
      <Route path="/pages/general" exact component={GeneralContent} />
      <Route path="/" exact component={Home} />
      {isAdmin() ? (
        <>
          <Route path="/user/edit/" exact component={RegisterEdit} />
          <Route path="/users" exact component={Users} />
          <Route path="/contents/:id/edit/" exact component={ContentEdit} />
          <Route path="/contents/new/" exact component={ContentNewContainer} />
        </>
      ) : (
        ""
      )}
    </Switch>
  );
};

export default Routes;
