import React from "react";

function General(props) {
	return (
		<section id="about">
			<div className="container">
				<div className="about-header">RIVERWIDE SAFARIS - COMPANY PROFILE</div>
				<div className="general-info">
					RIVERWIDE SAFARIS is a private limited tour and travel company fully incorporated in Uganda.
					- Quality of the driving - Reliability of the fleet - Knowledge of the place - Attention to details
					- Lack of integrity/accountability
					- Delayed pickup at airports - Poor time keeping - Poor hotel experience
					[Alternative routes, accomodation, meals]
					CORE VALUES
					- Excellence - Integrity, Community (sustainability)

					- You are driven by the desire to see people have a memorable tour and travel experience (build confidence in a client, reliability, experiential)

					- Member of UTB
					- Socials (X - riverwide, Instagram, TikTok)
				</div>
				<div className="about-sub-header">Vision</div>
				<div className="vision">

				</div>
				<br />
				<div>

				</div>
				<br />
				<div className="about-sub-header">The seven cornerstones</div>
				<br />
				<div>

				</div>
				<br />
				<div>

				</div>
				<br />
				<div className="about-sub-header">What we believe</div>
				<br />
				<div>

				</div>
				<br />
				<div>

				</div>
				<br />
				<div>

				</div>
				<br />
			</div>
		</section>
	);
}

export default General;
