/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as contentActions from "../../redux/actions/contentActions";
import * as eventActions from "../../redux/actions/eventActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../Preloader/Preloader";
import Ministry from "./Ministry";
import Contact from "./Contact";
import Featured from "./Featured";
import Slider from "./Slider";
import About from "./About";
import Giving from "./Giving";
import Slider1 from "../../assets/images/slider/slider1.png";
import Slider2 from "../../assets/images/slider/slider2.png";
import Slider3 from "../../assets/images/slider/slider3.png";
import "./Home.scss";
import "../../assets/css/media.css";
import Blog from "./Blog";
import Team from "./Team";


toast.configure({
  autoClose: 8000,
  draggable: false,
});

class Home extends React.Component {
  componentDidMount() {
    const { actions, eActions } = this.props;
    //actions.fetchContents(this.search);
    eActions.fetchEvents({
      types: "",
      title: "",
      startDateTime: "",
      endDateTime: ""
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);

    this.activePage = 1;
    this.search = {
      category: "",
      title: "",
      page: this.activePage
    };

    this.myToast = null;
  }


  render() {
    const {
      contents = [{
        id: 1,
        details: "This is me testing",
        path: Slider1,
        category: "Slider",
        active: false
      }],
      events = [],
      isLoadingContents = true,
      isLoadingEvents = true,
      error = "",
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (isLoadingContents || isLoadingEvents) ? <Preloader /> : (
      <>
        <Slider contents={[{
        id: 1,
        details: "<div class='slider-header'>CUSTOMIZED SAFARIS</div><div class='slider-caption'>Crafting the ultimate adventure experience to your taste is our specialty. Whether you seek adrenaline-pumping escapades or tranquil explorations, we tailor each journey to suit your preferences perfectly. Join us for an adventure that's uniquely yours.</div>",
        path: Slider1,
        category: "Slider",
        active: false
      },{
        id: 2,
        details: "<div class='slider-header'>ASSORTED FLEET</div><div class='slider-caption'>Our fleet comprises a diverse selection of fully insured vehicles, offering reliability both on all terrain. Our drivers are extensively trained and exceptionally skilled.</div>",
        path: Slider2,
        category: "Slider",
        active: false
      },{
        id: 3,
        details: "<div class='slider-header'>EXPLORE EAST AFRICA</div><div class='slider-caption'>Embark on a captivating journey to discover the wonders of East Africa from the misty ranges of the Rwenzori to the breathtaking savannahs of the Serengeti. Experience the pristine beaches of Zanzibar and track the silverbacks of Ruhengeri.</div>",
        path: Slider3,
        category: "Slider",
        active: false
      }]}/>
        <Contact />
      </>
    );
  }
}

Home.propTypes = {
  actions: PropTypes.object.isRequired,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingContents: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    contents: state.contents.contents,
    events: state.events.events,
    totalRecords: state.contents.totalRecords,
    error: state.contents.error,
    isLoading: state.contents.isLoading,
    isLoadingContents: state.contents.isLoadingContents,
    isLoadingEvents: state.events.isLoadingEvents,
    response: state.contents.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(contentActions, dispatch), eActions: bindActionCreators(eventActions, dispatch)  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);