import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { isAuth, isAdmin } from "../../utils/Authentication";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';

export default function AnonAppBar() {
  const [isLoggedIn, setLoggedIn] = useState(isAuth());

  React.useEffect(() => {
    document.addEventListener("login", (e) => {
      console.log(`Login ${isLoggedIn}`);
      setLoggedIn(true);
    });
    document.addEventListener("logout", (e) => {
      console.log(`Logout ${isLoggedIn}`);
      setLoggedIn(false);
    });
  }, []);

  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-riverwide fixed-top main-nav">
      <div class="container">
        <a class="navbar-brand" href="/"><img src={logo} className="logo" /></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="/about">About Us</a>
            </li>
            <li class="nav-item dropdown">
            <a class="nav-link" href="/uganda">Explore Uganda</a>
            </li>
            <li class="nav-item dropdown">
            <a class="nav-link" href="/rwanda">Visit Rwanda</a>
            </li>
            <li class="nav-item dropdown">
            <a class="nav-link" href="/about">Adventure Tanzania</a>
            </li>
            <li class="nav-item dropdown">
            <a class="nav-link" href="/about">Experience East Africa</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#contact">Contact</a>
            </li>
            {isAuth() ? (
              <>
                {isAdmin() ?
                  <>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Admin Area
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" href="/contents">Contents</a></li>
                        <li><a class="dropdown-item" href="/users">Users</a></li>
                      </ul>
                    </li>
                  </>
                  : <></>
                }
                <li class="nav-item"><a href="/logout" className="nav-link">Logout</a></li>
              </>
            ) : (
              <>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
