/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import General from "../General";
import "../../../../assets/css/media.css";

import It1 from "./it1.png";
import Gorilla from "./gorilla.jpg";
import Elephant from "./elephant.jpg";
import Leopard from "./leopard.jpg";
import Rhino from "./rhino.jpg";
import Lions from "./lions.jpg";
import Giraffe from "./giraffe.jpg";
import Team from "../Team";


toast.configure({
  autoClose: 8000,
  draggable: false,
});

class Rwanda extends React.Component {
  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);

    this.activePage = 1;
    this.search = {
      category: "",
      title: "",
      page: this.activePage
    };

    this.myToast = null;
  }


  render() {
    const {
      error = "",
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (
      <><br />
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="card">
                <img src={Gorilla} class="card-img-top" alt="..." />
                <div className="card-body">
                  <a href="/contents/3">2 Days Affordable Gorilla Trekking in Uganda from Rwanda</a>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={It1} class="card-img-top" alt="..." />
                <div className="card-body"><a href="/contents/3">3 Days Rwanda Gorilla Trekking Safari</a></div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={Elephant} class="card-img-top" alt="..." />
                <div className="card-body">3 Days Bwindi Gorilla Trekking Safari from Kigali</div>
              </div>
            </div>
          </div><br />
          <div className="row">
            <div className="col-4">
              <div className="card">
                <img src={It1} class="card-img-top" alt="..." />
                <div className="card-body">3 days Mgahinga gorillas and golden monkeys safari from Rwanda</div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={Lions} class="card-img-top" alt="..." />
                <div className="card-body">5 days Lake Mburo, Queen Elizabeth and Uganda Gorillas</div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={Elephant} class="card-img-top" alt="..." />
                <div className="card-body">5 Days Uganda Wildlife and Bwindi Gorilla Safari</div>
              </div>
            </div>
          </div><br />
          <div className="row">
            <div className="col-4">
              <div className="card">
                <img src={It1} class="card-img-top" alt="..." />
                <div className="card-body">5 Days Uganda Gorilla and Chimpanzee Trekking Safari from Kigali</div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={It1} class="card-img-top" alt="..." />
                <div className="card-body">7 Days Uganda Gorilla and Chimpanzee Safari</div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={Elephant} class="card-img-top" alt="..." />
                <div className="card-body">9 Day Safari in Uganda</div>
              </div>
            </div>
          </div><br />
          <div className="row">
            <div className="col-4">
              <div className="card">
                <img src={Leopard} class="card-img-top" alt="..." />
                <div className="card-body">10 Day Safari in Uganda</div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={Giraffe} class="card-img-top" alt="..." />
                <div className="card-body">12 days safari in Uganda, primates and Kidepo Valley</div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img src={Rhino} class="card-img-top" alt="..." />
                <div className="card-body">14 Day Safari in Uganda</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Rwanda.propTypes = {
  actions: PropTypes.object.isRequired,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingContents: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default Rwanda;